import { LegacyComponents } from '@volusion/element-components';
import { ElementPropTypes } from '@volusion/element-proptypes';
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/es/string';
import 'core-js/features/promise';
import * as PubSub from 'pubsub-js';
import 'whatwg-fetch';

import {
  BlockExports,
  ClientUtils,
  ConfiguredColor,
  ExternalDependencies,
  ExternalGlobalStyles,
  RehydrationData,
} from './types';

import addScript from './addScript';
import Algolia from './Algolia';
import {
  configureComponents,
  getBlock,
  getBlockWindowIdentifier,
} from './Blocks';
import { canonicalUrl, setCanonicalUrl } from './canonicalUrl';
import { Client } from './Client';
import { V1Client } from './V1Client';
import events from './events';
import joinClasses from './joinClasses';
import { Seo } from './Seo';

import {
  DEPRECATED_createLegacyButtonHelper,
  getActivePalette,
  getColor as getConfiguredColor,
} from './LegacyComponentHelper';

import * as aphroditeModule from 'aphrodite/no-important';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const aphrodite = (aphroditeModule as any).default;

interface MyWindow extends Window {
  rehydrate: RehydrationData;
  PubSub: PubSubJS.Base;
  aphrodite: unknown;
  'aphrodite/no-important': unknown;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  globalStyles: any;
  algoliasearch: ExternalDependencies.AlgoliaSearch;
  ElementSdk: { client: unknown; v1Client: unknown };
  [key: string]: unknown;
}

const myWindow: MyWindow = window as MyWindow & typeof globalThis;

const stylesObj = {
  StyleSheet: aphrodite.StyleSheet,
  css: aphrodite.css,
};

myWindow.aphrodite = aphrodite;
myWindow['aphrodite/no-important'] = aphrodite;

const fetch = window.fetch.bind(window);
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const client = new Client(fetch as any, new Algolia());
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const v1Client = new V1Client(fetch as any);
const seo = new Seo();

const delayedHydration = (
  React: ExternalDependencies.React,
  ReactDOM: React.ReactDOM
): void => {
  const chunks = myWindow.rehydrate.chunks;
  const delayedBlocks = chunks.filter((chunk) => chunk.delay);
  executeBlocks(React, ReactDOM, getBlocksMap(delayedBlocks));
};

const hydrateBlocks = (
  React: ExternalDependencies.React,
  ReactDOM: React.ReactDOM
): void => {
  const chunks = myWindow.rehydrate.chunks;
  const blocks = chunks.filter((chunk) => !chunk.delay);
  executeBlocks(React, ReactDOM, getBlocksMap(blocks));
};

type Section = {
  configuredBlockId: string;
  props: { [key: string]: unknown };
};
type BlockMapEntry = {
  block: {
    id: string;
    name: string;
    updatedOn: string;
    url: string;
    version: number;
  };
  sections: Section[];
};
type BlockMap = { [key: string]: BlockMapEntry };

const getBlocksMap = (chunks: MyWindow['rehydrate']['chunks']) => {
  const map: BlockMap = {};
  chunks.forEach((chunk: MyWindow['rehydrate']['chunks'][0]) => {
    const sectionKey = `${chunk.blockId}-${chunk.version}`;
    map[sectionKey] = {
      block: {
        id: chunk.blockId,
        name: chunk.name,
        updatedOn: chunk.updatedOn,
        url: chunk.url,
        version: chunk.version,
      },
      sections: (
        (map[`${chunk.blockId}-${chunk.version}`] &&
          map[`${chunk.blockId}-${chunk.version}`].sections) ||
        []
      ).concat([
        {
          configuredBlockId: chunk.configuredBlockId,
          props: chunk.props,
        },
      ]),
    };
  });
  return map;
};

const hexToRGB = (hex: string, withAlpha = false) => {
  let r = '0';
  let g = '0';
  let b = '0';

  // 3 digits
  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
    // 6 digits
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }

  if (withAlpha) {
    return `rgba(${+r}, ${+g}, ${+b}, 1)`;
  }
  return `rgb(${+r}, ${+g}, ${+b})`;
};

const rgbaToHex = ({
  r,
  g,
  b,
  a = 1,
}: {
  r: number;
  g: number;
  b: number;
  a?: number;
}) => {
  const [red, green, blue] = [r, g, b].map((value) =>
    value.toString(16).padStart(2, '0')
  );
  const baseHex = `#${red}${green}${blue}`;
  if (a === 1) {
    return baseHex;
  }
  const alpha = (Math.floor(a * 256) - 1).toString(16).padStart(2, '0');
  return `${baseHex}${alpha}`;
};

const normalizeColorAs = (
  colorString: string,
  targetFormat: 'hex' | 'rgb' | 'rgba'
) => {
  const hexColor = colorString.match(/^#([0-9a-f]{3}){1,2}$/i);
  const rgbaColor = colorString.match(
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/
  );
  const WITH_ALPHA = true;

  if (hexColor) {
    if (targetFormat === 'rgb') {
      return hexToRGB(colorString);
    }
    if (targetFormat === 'rgba') {
      return hexToRGB(colorString, WITH_ALPHA);
    }
    return colorString;
  }

  if (rgbaColor) {
    const [, r, g, b, a] = rgbaColor.filter((c) => c).map(parseFloat);
    if (targetFormat === 'rgb') {
      return `rgb(${r}, ${g}, ${b})`;
    }
    if (targetFormat === 'rgba') {
      return `rgba(${r}, ${g}, ${b}, ${a ?? '1'})`;
    }

    return rgbaToHex({ r, g, b, a });
  }
};

const executeBlocks = (
  React: ExternalDependencies.React,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ReactDOM: any,
  blocksMap: { [key: string]: BlockMapEntry }
) => {
  const head = document.getElementsByTagName('head');
  const globalStylesConfig =
    myWindow.globalStyles.stylesConfig || myWindow.globalStyles;

  const DEPRECATED_getLegacyButton = DEPRECATED_createLegacyButtonHelper({
    Components: LegacyComponents,
    globalStylesConfig,
  });

  const isConfiguredColor = (
    color: string | ConfiguredColor
  ): color is ConfiguredColor => {
    return typeof color !== 'string';
  };

  const palette = getActivePalette(globalStylesConfig.color);
  const getColor = (c: string | ConfiguredColor) => {
    return isConfiguredColor(c) ? getConfiguredColor(c, palette) : c;
  };

  const paletteColors = Object.keys(palette.colors).reduce(
    (carry, color) => ({
      ...carry,
      [color]: normalizeColorAs(palette.colors[color], 'hex'),
    }),
    {}
  );

  const externalGlobalStyles: ExternalGlobalStyles = {
    colors: {
      background: getColor(globalStylesConfig.color.background),
      primary: getColor(globalStylesConfig.color.primary),
      link: getColor(globalStylesConfig.color.link),
      linkHover: getColor(globalStylesConfig.color.linkHover),
      salePrice: getColor(globalStylesConfig.color.salePrice),
      secondary: getColor(globalStylesConfig.color.secondary),
      text: getColor(globalStylesConfig.color.text),
      palette: paletteColors,
    },
    typography: {
      headingFontFamily: globalStylesConfig.typography.headingFontFamily,
      baseFontFamily: globalStylesConfig.typography.fontFamily,
      baseFontSize: globalStylesConfig.typography.baseFontSize,
      lineHeight: globalStylesConfig.typography.lineHeight,
    },
  };

  const blockUtils = {
    DEPRECATED_getLegacyButton,
    addAmpScript: () => undefined,
    addLink: () => undefined,
    addScript,
    canonicalUrl: canonicalUrl(window),
    setCanonicalUrl,
    client: myWindow.ElementSdk.client,
    v1Client: myWindow.ElementSdk.v1Client,
    events,
    pubSub: myWindow.PubSub,
    storeUrl: myWindow.rehydrate.storeInfo?.storeUrl ?? window.location.origin,
    isTestEnvironment: myWindow.rehydrate.isTestEnvironment,
    globalStyles: externalGlobalStyles,
  };

  Object.keys(blocksMap).forEach((key) => {
    const { block, sections } = blocksMap[key];
    const script = document.createElement('script');
    script.src = `${block.url}?t=${block.updatedOn}`;
    script.onload = () => {
      const blockWindowIdentifier = getBlockWindowIdentifier(
        myWindow,
        block.id,
        block.name,
        block.version
      );
      sections.forEach((section: Section) => {
        const blockExports = myWindow[blockWindowIdentifier] as BlockExports;
        const blockSpec = getBlock(blockExports, {
          ElementPropTypes,
          React,
          aphrodite: stylesObj,
          blockConfig: { ...section.props },
          blockUtils: blockUtils as ClientUtils,
          globalStylesConfig,
        });

        const elements = Array.from(
          document.querySelectorAll(
            `[data-vol-id ="${section.configuredBlockId}"]`
          )
        );

        const componentProps = configureComponents(
          section.props,
          React,
          globalStylesConfig
        );

        elements.forEach((element) => {
          ReactDOM.hydrate(
            React.createElement(blockSpec.block, {
              ...section.props,
              ...componentProps,
              joinClasses,
              utils: blockUtils,
            }),
            element
          );
        });
      });
    };
    head[0].appendChild(script);
  });
};

export {
  DEPRECATED_createLegacyButtonHelper,
  joinClasses,
  addScript,
  client,
  v1Client,
  events,
  PubSub,
  seo,
  hydrateBlocks,
  delayedHydration,
  setCanonicalUrl,
  ElementPropTypes,
};
