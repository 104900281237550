import { Bag } from './Bag';
import { Cart } from './Cart';
import { Categories } from './Categories';
import { ContentPages } from './ContentPages';
import { LambdaApiV2 } from './LambdaApiV2';
import { LambdaV2DTO } from './LambdaV2DTO';
import { Menus } from './Menus';
import { PayPal } from './PayPal';
import { Products } from './Products';
import { StoreInfo } from './StoreInfo';
import Algolia from './Algolia';
import {
  Configuration,
  Fetch,
  Request,
  RequestInit,
  Response,
  StoreInfoResponse,
} from './types';

export class Client {
  private _products: Products | undefined;
  private _categories: Categories | undefined;
  private _cart: Cart | undefined;
  private _contentPages: ContentPages | undefined;
  private _storeInfo: StoreInfo | undefined;
  private _bag: Bag | undefined;
  private _menus: Menus | undefined;
  private _payPal: PayPal | undefined;
  private _configuration: Configuration | undefined;
  private _storeInformation: StoreInfoResponse | undefined;
  constructor(protected _transport: Fetch, protected _algolia: Algolia) {}

  public request(url: Request | string, init?: RequestInit): Promise<Response> {
    if (this._configuration && this._configuration.logger) {
      this._configuration.logger(
        `SDK request: ${JSON.stringify(url)} - Req config: ${JSON.stringify(
          init || {}
        )}`
      );
    }

    return this._transport(url, init).then((res: Response) => {
      if (!res.ok) {
        throw new Error(`Error ${res.status} ${url}`);
      }
      return res;
    });
  }

  public configure(config: Configuration): void {
    this._configuration = config;
    const api = new LambdaApiV2(this.request.bind(this), this._configuration);
    this._products = new Products(api, new LambdaV2DTO(), this._algolia);
    this._categories = new Categories(api, new LambdaV2DTO());
    this._cart = new Cart(api, new LambdaV2DTO());
    this._contentPages = new ContentPages(api, new LambdaV2DTO());
    this._storeInfo = new StoreInfo(api, new LambdaV2DTO());
    this._bag = new Bag(api, new LambdaV2DTO());
    this._menus = new Menus(api, new LambdaV2DTO());
    this._payPal = new PayPal(api, new LambdaV2DTO());
  }

  public setStoreInfo(storeInfo: StoreInfoResponse): void {
    this._storeInformation = storeInfo;
  }

  get products(): Products | undefined {
    this.checkConfiguredOrFail();
    return this._products;
  }

  get categories(): Categories | undefined {
    this.checkConfiguredOrFail();
    return this._categories;
  }

  get cart(): Cart | undefined {
    this.checkConfiguredOrFail();
    return this._cart;
  }

  get contentPages(): ContentPages | undefined {
    this.checkConfiguredOrFail();
    return this._contentPages;
  }

  get storeInfo(): StoreInfo | undefined {
    this.checkConfiguredOrFail();
    return this._storeInfo;
  }

  get bag(): Bag | undefined {
    this.checkConfiguredOrFail();
    return this._bag;
  }

  get menus(): Menus | undefined {
    this.checkConfiguredOrFail();
    return this._menus;
  }

  get payPal(): PayPal | undefined {
    this.checkConfiguredOrFail();
    return this._payPal;
  }

  get tenant(): string {
    this.checkConfiguredOrFail();
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return this._configuration!.tenant;
  }

  get storeInformation(): StoreInfoResponse | undefined {
    this.checkConfiguredOrFail();
    return this._storeInformation;
  }

  private checkConfiguredOrFail(): void {
    if (!this._configuration) {
      throw new Error('Volusion API client not configured');
    }
  }
}
