import {
  ApplePayOrderArgs,
  ApplePayOrderResponse,
  AvailableShippingMethods,
  CartAddress,
  CartContactInfo,
  CartInputContactInfo,
  CartResponse,
  CartTax,
  CartTotalItemsResponse,
  CountriesResponse,
  DTO,
  LambdaV2Responses,
  PaymentKeysResponse,
  ShippingMethod,
  StripeToken,
  VolusionApi,
} from './types';

import { splitName, transformAddress } from './CartUtils';

export class Cart {
  constructor(private _api: VolusionApi, private _dto: DTO) {}

  public async create(): Promise<CartResponse> {
    const data = await this._api.createCart();
    return this._dto.buildCartResponse(data);
  }

  public async get(cartId: string): Promise<CartResponse> {
    const data = await this._api.getCart(cartId);
    return this._dto.buildCartResponse(data);
  }

  public async getForShopper(
    cartId: string,
    shopperId?: string,
    shopperToken?: string
  ): Promise<CartResponse> {
    let data;
    if (!shopperId || !shopperToken) {
      data = await this._api.copyCartWithoutPersonalData(cartId);
    } else {
      data = await this._api.getLatestCartForShopper(
        cartId,
        shopperId,
        shopperToken
      );
    }
    return this._dto.buildCartResponse(data);
  }

  public async update(
    cartId: string,
    newQuantity: number,
    variantId: string
  ): Promise<CartResponse> {
    await this._api.updateCart(cartId, newQuantity, variantId);
    const data = await this._api.getCart(cartId);
    return this._dto.buildCartResponse(data);
  }

  public async addContactAndShipping(
    cartId: string,
    contactInfo: CartInputContactInfo,
    shippingMethod?: ShippingMethod
  ): Promise<CartResponse> {
    const cartContactInfo = this.getCartContactInfo(contactInfo);
    await this._api.addContactAndShippingToCart(
      cartId,
      cartContactInfo,
      shippingMethod
    );
    const data = await this._api.getCart(cartId);
    return this._dto.buildCartResponse(data);
  }

  public async add(
    cartId: string,
    productId: string,
    quantity: number,
    variantId: string
  ): Promise<CartResponse> {
    const data = await this._api.addToCart(
      cartId,
      productId,
      quantity,
      variantId
    );
    return this._dto.buildCartResponse(data);
  }

  public async setShopperId(cartId: string, shopperId: string): Promise<void> {
    await this._api.setShopperId(cartId, shopperId);
  }

  public async getTotalItems(cartId: string): Promise<CartTotalItemsResponse> {
    const data = await this._api.getCart(cartId);
    return this._dto.buildCartTotalItemsResponse(data);
  }

  public async addDiscount(
    cartId: string,
    discountCode: string
  ): Promise<CartResponse> {
    const data = await this._api.addDiscountToCart(cartId, discountCode);
    return this._dto.buildCartResponse(data);
  }

  public async removeDiscount(
    cartId: string,
    discountId: string
  ): Promise<CartResponse> {
    const data = await this._api.removeDiscountFromCart(cartId, discountId);
    return this._dto.buildCartResponse(data);
  }

  public async getShipToCountries(): Promise<CountriesResponse> {
    const data = await this._api.getShipToCountries();
    return this._dto.buildCountriesResponse(data);
  }

  public async getPaymentKeys(): Promise<PaymentKeysResponse> {
    const data = await this._api.getPaymentKeys();
    return this._dto.buildPaymentKeysResponse(data);
  }

  public async getShippingMethods(
    cartId: string,
    country: string,
    state: string
  ): Promise<AvailableShippingMethods> {
    return this._api.getAvailableShippingMethods(cartId, country, state);
  }

  public async getTax(
    cartId: string,
    shippingAddress: CartAddress,
    shippingPrice: number
  ): Promise<CartTax> {
    return this._api.getTax(cartId, shippingAddress, shippingPrice);
  }

  public async placeApplePayOrder(
    cartId: string,
    stripeToken: StripeToken
  ): Promise<ApplePayOrderResponse | void> {
    const cart = await this._api.getCart(cartId);
    const { billingAddress, shippingAddress } =
      cart as LambdaV2Responses['cart'];
    if (billingAddress && shippingAddress) {
      const data = await this._api.placeApplePayOrder(
        cart as LambdaV2Responses['cart'],
        stripeToken
      );
      if (data) {
        return this._dto.buildApplePayOrderResponse(data);
      }
      throw new Error('Order could not be placed');
    } else {
      throw new Error('Billing and shipping address are required');
    }
  }

  public async placeApplePayOrderWithRestCart({
    billingAddress,
    cartId,
    contactInfo,
    selectedShippingMethod,
    shippingAddress,
    stripeToken,
    tax,
  }: ApplePayOrderArgs): Promise<ApplePayOrderResponse | void> {
    if (billingAddress && shippingAddress) {
      const cart = (await this._api.getCart(
        cartId
      )) as LambdaV2Responses['newCart'];

      const data = await this._api.placeApplePayOrderWithNewCart({
        cart,
        stripeToken,
        contactInfo,
        billingAddress,
        selectedShippingMethod,
        shippingAddress,
        tax,
      });

      if (data) {
        return this._dto.buildApplePayOrderResponse(data);
      }

      throw new Error('Order could not be placed.');
    } else {
      throw new Error('Billing and shipping address are required');
    }
  }

  private getCartContactInfo(
    contactInfo: CartInputContactInfo
  ): CartContactInfo {
    const shippingContactName = splitName(contactInfo.shippingName);
    const shippingAddress = transformAddress(
      contactInfo.shippingAddress,
      shippingContactName
    );
    const billingContactName = splitName(contactInfo.billingName);
    const billingAddress = transformAddress(
      contactInfo.billingAddress,
      billingContactName
    );
    return {
      billingAddress,
      email: contactInfo.email,
      shippingAddress,
    };
  }
}
