import { V1StoreInfo } from './V1StoreInfo';
import {
  Fetch,
  Request,
  RequestInit,
  Response,
  V1Configuration,
} from './types';
import { LambdaApiV1 } from './LambdaApiV1';
import {
  ConfigVariable,
  ConfigVariablesMap,
  PageText,
  PageTextsMap,
  StoreConfig,
} from './v1-types';

export class V1Client {
  private _storeInfo: V1StoreInfo | undefined;
  private _configuration: V1Configuration | undefined;
  private _storeInformation: StoreConfig | undefined;
  private _configVariables: ConfigVariable[] | undefined;
  private _pageText: PageText[] | undefined;
  constructor(protected _transport: Fetch) {}

  public request(url: Request | string, init?: RequestInit): Promise<Response> {
    if (this._configuration && this._configuration.logger) {
      this._configuration.logger(
        `SDK request: ${JSON.stringify(url)} - Req config: ${JSON.stringify(
          init || {}
        )}`
      );
    }

    init = initHeaders(init);

    return this._transport(url, init).then((res: Response) => {
      if (!res.ok) {
        throw new Error(`Error ${res.status} ${url}`);
      }
      return res;
    });
  }

  public configure(config: V1Configuration): void {
    this._configuration = config;
    const api = new LambdaApiV1(this.request.bind(this), this._configuration);
    this._storeInfo = new V1StoreInfo(api);
  }

  public setStoreInfo(storeInfo: StoreConfig): void {
    this._storeInformation = storeInfo;
  }

  public async retrieveConfigVariables(): Promise<ConfigVariable[]> {
    if (!this._configuration) {
      this.failConfiguredCheck();
    }

    return new LambdaApiV1(
      this.request.bind(this),
      this._configuration
    ).getConfigVariables();
  }

  public setConfigVariables(configVariables: ConfigVariable[]): void {
    this._configVariables = configVariables;
  }

  public getConfigVariablesById(
    configVariableIds: string[]
  ): ConfigVariablesMap {
    if (this._configVariables === undefined) {
      throw new Error('Volusion V1 client config variables not configured');
    }

    return this._configVariables.reduce<ConfigVariablesMap>(
      (configVariablesIdMap, configVariable) => {
        if (configVariableIds.includes(configVariable.name)) {
          configVariablesIdMap[configVariable.name] = configVariable.value;
        }

        return configVariablesIdMap;
      },
      {}
    );
  }

  public async retrievePageText(): Promise<PageText[]> {
    if (!this._configuration) {
      this.failConfiguredCheck();
    }

    return new LambdaApiV1(
      this.request.bind(this),
      this._configuration
    ).getPageText();
  }

  public setPageText(pageText: PageText[]): void {
    this._pageText = pageText;
  }

  public getPageTextsById(pageTextIds: number[]): PageTextsMap {
    if (!this._pageText) {
      throw new Error('Volusion V1 client page text not configured');
    }

    return this._pageText.reduce<PageTextsMap>((pageTextsMap, pageText) => {
      if (pageTextIds.includes(pageText.id)) {
        pageTextsMap[pageText.id] = pageText.text;
      }

      return pageTextsMap;
    }, {});
  }

  get storeInfo(): V1StoreInfo | undefined {
    this.checkConfiguredOrFail();
    return this._storeInfo;
  }

  get storeInformation(): StoreConfig | undefined {
    this.checkConfiguredOrFail();
    return this._storeInformation;
  }

  private checkConfiguredOrFail(): void {
    if (!this._configuration) {
      this.failConfiguredCheck();
    }
  }

  private failConfiguredCheck(): never {
    throw new Error('Volusion V1 API client not configured');
  }
}

export function initHeaders(init: RequestInit | undefined): RequestInit {
  const requestFromRendererHeaderName = 'x-vol-request-from-renderer';
  const requestFromRendererHeaderValue = 'true';
  const userAgentHeaderName = 'user-agent';
  const userAgentHeaderValue = 'zero-Sdk/2.x';

  if (
    init?.headers !== undefined &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (init?.headers as any)?.set === 'function'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setHeader = (init.headers as any).set.bind(init.headers);
    setHeader(requestFromRendererHeaderName, requestFromRendererHeaderValue);
    setHeader(userAgentHeaderName, userAgentHeaderValue);
    return init;
  }

  if (init?.headers !== undefined && Array.isArray(init.headers)) {
    init.headers.push([
      requestFromRendererHeaderName,
      requestFromRendererHeaderValue,
    ]);
    init.headers.push([userAgentHeaderName, userAgentHeaderValue]);
    return init;
  }

  if (init === undefined) {
    init = {} as RequestInit;
  }
  if (init.headers === undefined) {
    init.headers = {};
  }

  const headers = init.headers as { [key: string]: string };
  headers[requestFromRendererHeaderName] = requestFromRendererHeaderValue;
  headers[userAgentHeaderName] = userAgentHeaderValue;

  return init;
}
