import { BagResponse, DTO, VolusionApi } from './types';

export class Bag {
  constructor(private _api: VolusionApi, private _dto: DTO) {}

  public create(cartId: string, returnUri: string): Promise<BagResponse> {
    return this._api
      .createBag(cartId, returnUri)
      .then(this._dto.buildBagResponse);
  }
}
