const isScriptLoaded = (src: string): boolean => {
  const scripts = document.querySelectorAll(
    'script'
  ) as NodeListOf<HTMLScriptElement>;
  const scriptsArray = Array.prototype.slice.call(scripts);
  return scriptsArray.find(
    (script: HTMLScriptElement) => script.getAttribute('src') === src
  );
};

const addScript = (src: string, defer?: boolean, async?: boolean): void => {
  if (isScriptLoaded(src)) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('src', src);
  if (defer) {
    script.setAttribute('defer', '');
  }
  if (async) {
    script.setAttribute('async', '');
  }
  document.head.appendChild(script);
};

export default addScript;
