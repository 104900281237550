import { DTO, StoreInfoResponse, VolusionApi } from './types';

export class StoreInfo {
  constructor(private _api: VolusionApi, private _dto: DTO) {}

  public get(): Promise<StoreInfoResponse> {
    return this._api
      .getStoreInformation()
      .then(this._dto.buildStoreInfoResponse);
  }
}
