interface ObjectLiteral {
  [key: string]: string;
}

type CanonicalUrlHelper = (queryParams?: ObjectLiteral) => string;

let _canonicalUrl: string | undefined = undefined;

export const setCanonicalUrl = (canonicalUrl: string): void => {
  _canonicalUrl = canonicalUrl;
};

export const canonicalUrl =
  (window: Window): CanonicalUrlHelper =>
  (queryParams) => {
    if (_canonicalUrl !== undefined) {
      return _canonicalUrl;
    }
    const { origin, pathname } = window.location;
    const url = origin + decodeURIComponent(pathname);

    const allParams: ObjectLiteral = {
      ...(queryParams || {}),
    };

    const queryString = Object.keys(allParams)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          allParams[key]
        )}`;
      })
      .join('&');

    return queryString.length ? `${url}?${queryString}` : url;
  };
