import { VolusionV1Api } from './types';
import { StoreConfig } from './v1-types';

export class V1StoreInfo {
  constructor(private _api: VolusionV1Api) {}

  public get(cookieHeader: string): Promise<StoreConfig> {
    return this._api.getStoreInformation(cookieHeader);
  }
}
