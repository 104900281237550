import { LambdaV2Responses, ProductResponse } from './types';

export const buildProductVariantOptionsResponse = (
  variantOptions: LambdaV2Responses['product']['variantOptions'],
  productVariants: ProductResponse['productVariants']
): ProductResponse['variantOptions'] => {
  const visibleOptions = getVisibleVariantOptions(productVariants);
  return variantOptions.map((option, index) => {
    return {
      id: option.id || null,
      imageLinkIds: option.imageLinkIds,
      name: option.name,
      options: visibleOptions[index],
      order: option.order,
    };
  });
};

export const getVisibleVariantOptions = (
  activeVariantCombos: ProductResponse['productVariants']
): string[][] => {
  const variantsLength =
    activeVariantCombos.length && activeVariantCombos[0].variants
      ? activeVariantCombos[0].variants.length
      : 0;
  let visibleOptions = new Array(variantsLength).fill(null);
  visibleOptions = visibleOptions.map(() => []);
  activeVariantCombos.forEach((combination) => {
    if (combination.variants) {
      combination.variants.forEach((option, index) => {
        if (visibleOptions[index].indexOf(option) === -1) {
          visibleOptions[index].push(option);
        }
      });
    }
  });
  return visibleOptions;
};
