import * as algoliasearch from 'algoliasearch';
import Algolia from './Algolia';
import {
  CategoryProductsRequestParams,
  CategoryRequestParams,
  DTO,
  LambdaV2Responses,
  PagedProductResponse,
  ProductIndex,
  ProductResponse,
  SortOrder,
  VolusionApi,
} from './types';

type FacetFilters = Array<string | string[]>;

interface SearchParams {
  facetFilters?: FacetFilters;
  query: string;
  length?: number;
  page?: number;
  pageSize: number;
  offset?: number;
  sort: SortOrder;
}

export class Products {
  private _searchKeys: LambdaV2Responses['searchKeys'] | null = null;
  constructor(
    private _api: VolusionApi,
    private _dto: DTO,
    private _algolia: Algolia
  ) {}

  public async getById(id: string): Promise<ProductResponse> {
    const isNewProductId = !!Number(id);

    if (isNewProductId) {
      return this.getByProductId(id);
    } else {
      try {
        return await this.algoliaProductByFilterOrThrow(`objectID:${id}`);
      } catch (error) {
        const data = await this._api.getProductById(id);
        return this._dto.buildProductResponse(data);
      }
    }
  }

  public async getByProductId(productId: string): Promise<ProductResponse> {
    const data = await this._api.getNewProductById(productId);
    // use product and variant ids expected in new cart api
    data.id = data.productId;
    data.productVariants.forEach(
      (variant) => (variant.id = variant.variantId || '')
    );
    return this._dto.buildProductResponse(data);
  }

  public async getBySlug(slug: string): Promise<ProductResponse> {
    try {
      return await this.algoliaProductByFilterOrThrow(
        `seo.friendlyName:${slug}`
      );
    } catch (error) {
      const apiData = await this._api.getProductBySlug(slug);
      return this._dto.buildProductResponse(apiData);
    }
  }

  public async getByCategorySlug({
    slug,
    page = 1,
    pageSize = 20,
  }: CategoryProductsRequestParams): Promise<PagedProductResponse> {
    const startIndex = (page - 1) * pageSize;

    const filterBase = `filter=categorySlug+eq+${slug}`;
    const filter = `${filterBase}&pageSize=${pageSize}&startIndex=${startIndex}`;

    const data = await this._api.getProductsByCategorySlug(filter);

    const { totalCount, pageCount } = data;

    return this._dto.buildProductsResponse(
      data,
      totalCount || 0,
      pageCount || 0,
      page
    );
  }

  public async getByCategoryId({
    categoryId,
    offset,
    page,
    pageSize,
    sort,
  }: CategoryRequestParams): Promise<PagedProductResponse> {
    const categoryList = await this._api.getCategoryFlatList();
    const categoryAndSubCategories = this.getWithChildCategories(
      categoryId,
      categoryList
    );

    const searchOptions = {
      pageSize,
      query: '',
      sort,
    } as SearchParams;
    if (page !== undefined) {
      searchOptions.page = page;
    }
    // page takes precedence over offset: use offset only if page is undefined
    if (offset && page === undefined) {
      searchOptions.offset = offset;
      searchOptions.length = pageSize;
    }
    if (categoryAndSubCategories.length > 0) {
      const categoryFilter = categoryAndSubCategories.map(
        (id) => `categoryIds:${id}`
      );
      searchOptions.facetFilters = ['state:Active', categoryFilter];
    }
    return this.search(searchOptions);
  }

  public async getRelatedById(id: string): Promise<ProductResponse[]> {
    try {
      const product = await this.getById(id);
      const relatedIds = product.relatedProductIds;
      return await this.algoliaRelatedProducts(relatedIds);
    } catch (error) {
      const data = await this._api.getRelatedProductsById(id);
      return this._dto.buildRelatedProductsResponse(data);
    }
  }

  public async getRelatedBySlug(slug: string): Promise<ProductResponse[]> {
    try {
      const product = await this.getBySlug(slug);
      const relatedIds = product.relatedProductIds;
      return await this.algoliaRelatedProducts(relatedIds);
    } catch (error) {
      const data = await this._api.getRelatedProductsBySlug(slug);
      return this._dto.buildRelatedProductsResponse(data);
    }
  }

  public async search({
    facetFilters,
    query,
    page,
    pageSize,
    offset,
    sort,
  }: SearchParams): Promise<PagedProductResponse> {
    const data = await this.searchKeys();
    const params: algoliasearch.QueryParameters = {
      hitsPerPage: pageSize,
      query,
    };
    if (page !== undefined) {
      // page input is one-based, but Algolia search options page is zero-based
      const optionsPage = page > 0 ? page - 1 : 0;
      params.page = optionsPage;
    }
    if (facetFilters) {
      params.facetFilters = facetFilters;
    }
    // page takes precedence over offset: use offset only if page is undefined
    if (offset && page === undefined) {
      params.offset = offset;
      params.length = pageSize;
    }
    const index = this._algolia
      .prepare(data.applicationId, data.key)
      .index(this.productIndex(sort));

    const algoliaRes = await index.search(params);
    const totalPages = Math.ceil(algoliaRes.nbHits / pageSize);
    const pageForClient = this.getAdjustedPageNumber(
      algoliaRes.page,
      offset,
      pageSize
    );
    return this._dto.buildProductsResponse(
      {
        items: algoliaRes.hits,
      },
      algoliaRes.nbHits,
      totalPages,
      pageForClient
    );
  }

  /**
   * Gets an array that includes the categoryId, and all of its child categories.
   * @param id - the id of the parent category that we're searching for
   * @param categories - the category tree that we're searching
   */
  public getWithChildCategories(
    id: string,
    categories: Array<LambdaV2Responses['category']>
  ): string[] {
    let categoryIds = [id];
    const category = this.findCategory(
      id,
      categories
    ) as LambdaV2Responses['category'];
    if (category) {
      categoryIds = [...categoryIds, ...this.getChildCategoryIds(category)];
    }
    return categoryIds;
  }

  private async searchKeys(): Promise<LambdaV2Responses['searchKeys']> {
    if (this._searchKeys) {
      return this._searchKeys;
    }

    this._searchKeys = await this._api.searchKeys();
    return this._searchKeys;
  }

  /**
   * Helper function to recursively find the parent category in the category tree
   * @param id - the parent id we're searching for
   * @param categories - the category tree we're searching
   */
  private findCategory(
    id: string,
    categories: Array<LambdaV2Responses['category']>
  ): LambdaV2Responses['category'] | undefined {
    for (const category of categories) {
      if (category.id === id) {
        return category;
      }
      if (category.subCategories && category.subCategories.length > 0) {
        const matchingSubcategory = this.findCategory(
          id,
          category.subCategories as Array<LambdaV2Responses['category']>
        );
        if (matchingSubcategory) {
          return matchingSubcategory;
        }
      }
    }
  }

  /**
   * Helper function to recursively get all the child ids for a category
   * @param category - the category in the category tree
   */
  private getChildCategoryIds(
    category: LambdaV2Responses['category']
  ): string[] {
    let childIds: string[] = [];
    if (category.subCategories && category.subCategories.length > 0) {
      childIds = category.subCategories.map((subCategory) => subCategory.id);
      const grandChildIds = category.subCategories.map((subCategory) =>
        this.getChildCategoryIds(subCategory as LambdaV2Responses['category'])
      );
      childIds = [...childIds, ...this.flatten(grandChildIds)];
    }
    return childIds;
  }

  /**
   * Helper function to flatten an array. ex: [a, b, [c, d]] => [a, b, c, d];
   * @param array - the array to flatten.
   */
  private flatten(array: Array<string | string[]>): string[] {
    return ([] as string[]).concat(...array);
  }

  private productIndex(sort: SortOrder): ProductIndex {
    const sortBy = sort ? sort.toLowerCase() : sort;
    switch (sortBy) {
      case 'lowest price':
        return 'products_price_asc';
      case 'highest price':
        return 'products_price_desc';
      case 'newest':
        return 'products_newest';
      case 'name z-a':
        return 'products_name_desc';
      case 'name a-z':
        return 'products_name_asc';
      default:
        return 'products';
    }
  }

  private getAdjustedPageNumber(
    page: number | undefined,
    offset: number | undefined,
    pageSize: number
  ): number {
    if (page !== undefined) {
      // Algolia page response is zero-based, client wants one-based response
      return page + 1;
    }
    return Math.floor((offset || 0) / pageSize) + 1;
  }

  private async algoliaProductByFilterOrThrow(
    filter: string
  ): Promise<ProductResponse> {
    const searchOptions = {
      offset: 0,
      pageSize: 1,
      query: '',
      sort: 'name a-z',
    } as SearchParams;
    searchOptions.facetFilters = ['state:Active', filter];
    const response = await this.search(searchOptions);
    if (response.items.length > 0) {
      return response.items[0];
    }
    throw new Error(`Product with ${filter} not found in algolia`);
  }

  private async algoliaRelatedProducts(
    relatedIds: string[]
  ): Promise<ProductResponse[]> {
    return Promise.all(
      relatedIds.map((productId: string) => {
        return this.getById(productId);
      })
    );
  }
}
