import { DTO, ExpressCheckoutResponse, VolusionApi } from './types';

export class PayPal {
  constructor(private _api: VolusionApi, private _dto: DTO) {}

  public setExpressCheckout(
    returnUrl: string,
    cancelUrl: string,
    cartId: string
  ): Promise<ExpressCheckoutResponse> {
    return this._api
      .setExpressCheckout(returnUrl, cancelUrl, cartId)
      .then(this._dto.buildExpressCheckoutResponse);
  }

  public setRestExpressCheckout(
    cartId: string
  ): Promise<ExpressCheckoutResponse> {
    return this._api
      .setRestExpressCheckout(cartId)
      .then(this._dto.buildRestExpressCheckoutResponse);
  }
}
