import {
  CategoriesResponse,
  CategoryResponse,
  DTO,
  VolusionApi,
} from './types';

export class Categories {
  constructor(private _api: VolusionApi, private _dto: DTO) {}

  public async get(): Promise<CategoriesResponse> {
    const data = await this._api.getCategoryFlatList();
    return this._dto.buildCategoryListResponse(data);
  }

  public async getById(id: string): Promise<CategoryResponse> {
    const data = await this._api.getCategoryById(id);
    return this._dto.buildCategoryResponse(data);
  }

  public async getBySlug(slug: string): Promise<CategoryResponse> {
    const data = await this._api.getCategoryBySlug(slug);
    return this._dto.buildCategoryResponse(data);
  }
}
