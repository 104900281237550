import {
  ApplePayOrderPayload,
  CartAddress,
  CartAvailableShippingMethod,
  ContactName,
  DefaultOrder,
  LambdaV2Responses,
  ApplePayOrderRestCartPayload,
  OrderAddress,
  PhysicalAddress,
  ShippingMethod,
  StripeToken,
  ApplePayOrderForRestCartArgs,
} from './types';

export const transformAddress = (
  physicalAddress: PhysicalAddress,
  name: ContactName
): CartAddress => {
  return { ...physicalAddress, ...name };
};

// Split full name into firstName/lastName, splitting at the last space char
// Good: "John Paul Jones" -> "John Paul", "Jones"
// Bad: "Martin Van Buren" -> "Martin Van", "Buren"
export const splitName = (fullName: string): ContactName => {
  const nameParts = fullName.split(' ');
  const lastName = nameParts[nameParts.length - 1];
  const firstName = nameParts.slice(0, nameParts.length - 1).join(' ');
  return { firstName, lastName };
};

export const formatShippingMethod = (
  cartShippingMethod: CartAvailableShippingMethod
): ShippingMethod => {
  return {
    name: cartShippingMethod.name,
    shippingBand: {
      shippingPrice: cartShippingMethod.shippingPrice,
    },
  };
};

export const getOrderAddress = (cartAddress: CartAddress): OrderAddress => {
  return {
    ...cartAddress,
    companyName: '',
    phoneNumber: '',
    preferred: false,
    residential: true,
  };
};

export const createRequestId = (): string => {
  const REQUEST_ID_CHAR_LIMIT = 20;
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  return Array.from(Array(REQUEST_ID_CHAR_LIMIT), () => {
    const charIndex = Math.floor(Math.random() * chars.length);
    return chars.charAt(charIndex);
  }).join('');
};

export const getDefaultOrder = (): DefaultOrder => {
  return {
    cart: null,
    deliverTo: null,
    paymentMethod: 'Credit Card',
    placedBy: null,
  };
};

export const getApplePayOrder = (
  cart: LambdaV2Responses['cart'],
  stripeToken: StripeToken
): ApplePayOrderPayload => {
  const { billingAddress, shippingAddress } = cart;
  const billing = getOrderAddress(billingAddress || ({} as CartAddress));
  const shipping = getOrderAddress(shippingAddress || ({} as CartAddress));
  const defaultOrder = getDefaultOrder();
  return {
    ...defaultOrder,
    cart,
    creditCardLastFour: stripeToken.card.last4,
    creditCardType: stripeToken.card.brand,
    deliverTo: {
      address: shipping,
      firstName: shipping.firstName,
      lastName: shipping.lastName,
      phoneNumber: '',
    },
    grandTotal: cart.grandTotal,
    paymentMethod: 'Credit Card',
    paymentSource: 'Apple Pay',
    placedBy: {
      address: billing,
      emailAddress: cart.contactInfo.email,
      emailOptIn: false,
      firstName: billing.firstName,
      lastName: billing.lastName,
      phoneNumber: '',
    },
    requestId: createRequestId(),
    stripeCreditCardToken: stripeToken.id,
    taxAmount: cart.taxAmount,
    taxRate: cart.taxRate,
  };
};

export const getApplePayOrderForRestCart = ({
  cart,
  stripeToken,
  billingAddress,
  shippingAddress,
  contactInfo,
  selectedShippingMethod,
  tax,
}: ApplePayOrderForRestCartArgs): ApplePayOrderRestCartPayload => {
  const billing = getOrderAddress(billingAddress || ({} as CartAddress));
  const shipping = getOrderAddress(shippingAddress || ({} as CartAddress));

  const result: ApplePayOrderRestCartPayload = {
    cartId: cart.cartId,
    creditCardLastFour: stripeToken.card.last4,
    creditCardType: stripeToken.card.brand,
    deliverTo: {
      address: shipping,
      firstName: shipping.firstName,
      lastName: shipping.lastName,
      phoneNumber: '',
    },
    grandTotal: cart.pricing.total.amount / 100,
    paymentMethod: 'Credit Card',
    paymentSource: 'Apple Pay',
    placedBy: {
      address: billing,
      emailAddress: contactInfo.email,
      emailOptIn: false,
      firstName: billing.firstName,
      lastName: billing.lastName,
      phoneNumber: '',
    },
    requestId: createRequestId(),
    shippingMethod: selectedShippingMethod,
    stripeCreditCardToken: stripeToken.id,
    taxAmount: tax.amount,
    taxRate: tax.rate,
  };

  return result;
};
