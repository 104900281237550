type Topics = 'cart';

type CartSubTopic =
  | 'addToCart'
  | 'askForStripeKey'
  | 'askIfLoaded'
  | 'itemAddedToCart'
  | 'itemRemovedFromCart'
  | 'openAccountPanel'
  | 'openCart'
  | 'replyWithLoaded'
  | 'replyWithStripeKey'
  | 'updateCartCount'
  | 'askForTotalItems'
  | 'replyWithTotalItems';

interface TopicSubTopic {
  cart: { [K in CartSubTopic]: string };
}

type Events = { [K in Topics]: TopicSubTopic[K] };

const events: Events = {
  cart: {
    addToCart: 'cart.addToCart',
    askForStripeKey: 'cart.askForStripeKey',
    askForTotalItems: 'cart.askForTotalItems',
    askIfLoaded: 'cart.askIfLoaded',
    itemAddedToCart: 'cart.itemAddedToCart',
    itemRemovedFromCart: 'cart.itemRemovedFromCart',
    openAccountPanel: 'cart.openAccountPanel',
    openCart: 'cart.openCart',
    replyWithLoaded: 'cart.replyWithLoaded',
    replyWithStripeKey: 'cart.replyWithStripeKey',
    replyWithTotalItems: 'cart.replyWithTotalItems',
    updateCartCount: 'cart.updateCartCount',
  },
};

export default events;
