import {
  AnyObject,
  BlockExports,
  BlockSettings,
  BlockUtils,
  GlobalStyles,
  PropComponentPair,
  ExternalDependencies,
} from './types';

import {
  LegacyComponents,
  StandardComponents,
} from '@volusion/element-components';

interface BlockDependencies {
  React: ExternalDependencies.React;
  ElementPropTypes: unknown;
  aphrodite: { StyleSheet: unknown; css: unknown };
  globalStylesConfig: GlobalStyles;
  blockConfig: AnyObject;
  blockUtils: BlockUtils;
}

interface MyWindow extends Window {
  [key: string]: unknown;
}

export const getBlock = (
  blockModule: BlockExports,
  deps: BlockDependencies
): { block: React.Factory<AnyObject>; config?: AnyObject } => {
  if (blockModule.factory) {
    return blockModule.factory(
      {
        Components: LegacyComponents,
        ElementPropTypes: deps.ElementPropTypes,
        React: deps.React,
      },
      { ...deps.blockUtils },
      deps.aphrodite,
      deps.globalStylesConfig,
      deps.blockConfig
    );
  } else if (blockModule.block) {
    return {
      block: blockModule.block,
      config: blockModule.configSchema,
    };
  } else {
    throw new Error('Invalid Block Contract');
  }
};

export const getBlockWindowIdentifier = (
  myWindow: MyWindow,
  blockId: string,
  blockName: string,
  blockVersion: number
): string => {
  const volName = `volBlock${blockName}`;
  const versionVolName = `volBlock_${blockId}_${blockVersion}`;
  return myWindow[versionVolName] ? versionVolName : volName;
};

interface ComponentProps {
  [key: string]: React.SFC<{ [key: string]: unknown }>;
}

const configureComponent = (
  componentName: string,
  props: { [key: string]: unknown },
  React: ExternalDependencies.React
) => {
  if (!StandardComponents[componentName]) {
    return () => null;
  } else {
    const Component: React.FC = (customProps: { [key: string]: unknown }) =>
      React.createElement(StandardComponents[componentName].block, {
        ...customProps,
        ...props,
      });
    Component.displayName = componentName;
    return Component;
  }
};

export const configureComponents = (
  blockSettings: BlockSettings,
  React: ExternalDependencies.React,
  globalStylesConfig: GlobalStyles
): ComponentProps => {
  if (!blockSettings._metadata) {
    return {};
  } else {
    return blockSettings._metadata.components.reduce(
      (
        components: ComponentProps,
        [propName, componentName]: PropComponentPair
      ) => {
        const componentProps = {
          ...blockSettings[propName],
          globalSettings: globalStylesConfig,
        };
        return {
          ...components,
          [propName]: configureComponent(componentName, componentProps, React),
        };
      },
      {}
    );
  }
};
