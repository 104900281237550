import { Response } from 'node-fetch';
import {
  CartApiResponse,
  Configuration,
  LambdaV2Responses,
  NewProductResponse,
  Request,
  RequestInit,
} from './types';

type RequestFn = (
  url: Request | string,
  init?: RequestInit
) => Promise<Response>;

export abstract class CartApi {
  _baseUrl = 'https://api-v2.volusion.com';

  constructor(public _transport: RequestFn, public _config: Configuration) {
    if (_config.apiUri) {
      this._baseUrl = _config.apiUri;
    }
  }

  abstract addToCart(
    cartId: string,
    productId: string,
    quantity: number,
    variantId: string
  ): Promise<CartApiResponse | never>;

  abstract addDiscountToCart(
    cartId: string,
    discountCode: string
  ): Promise<CartApiResponse>;

  abstract createCart(): Promise<CartApiResponse>;

  abstract copyCartWithoutPersonalData(
    cartId: string
  ): Promise<CartApiResponse>;

  abstract getCart(cartId: string): Promise<CartApiResponse>;

  abstract getLatestCartForShopper(
    cartId: string,
    shopperId: string,
    shopperToken?: string
  ): Promise<CartApiResponse>;

  abstract removeDiscountFromCart(
    cartId: string,
    discountId: string
  ): Promise<CartApiResponse>;

  abstract setShopperId(
    cartId: string,
    shopperId: string
  ): Promise<CartApiResponse>;

  abstract updateCart(
    cartId: string,
    newQuantity: number,
    variantId: string
  ): Promise<CartApiResponse>;

  public logAndThrow = (
    error: Error,
    context: Record<string, any> = {}
  ): never => {
    if (this._config.logger) {
      let msg = `SDK request failure: ${error.message}`;

      if (context) {
        const allContext = JSON.stringify({
          ...context,
          tenant: this._config.tenant,
        });
        msg = `${msg} (${allContext})`;
      }

      this._config.logger(msg);
    }
    throw new Error(error.message);
  };
}

export class NewCartApi extends CartApi {
  public async addDiscountToCart(
    cartId: string,
    discountCode: string
  ): Promise<LambdaV2Responses['newCart'] | never> {
    try {
      await this._transport(
        `${this._baseUrl}/public/rest/carts/${cartId}/discounts`,
        {
          body: JSON.stringify({ discountCode: discountCode.toUpperCase() }),
          headers: {
            'x-vol-tenant': this._config.tenant,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      );

      return this.getCart(cartId);
    } catch (err) {
      return this.logAndThrow(err as Error, {
        cartId,
        discountCode: discountCode.toUpperCase(),
      });
    }
  }

  public async addToCart(
    cartId: string,
    productId: string,
    quantity: number,
    variantId: string
  ): Promise<LambdaV2Responses['newCart'] | never> {
    try {
      const product =
        await this.getProductIdAndVariantIdByLegacyProductIdAndLegacyVariantId(
          productId,
          variantId
        );

      await this._transport(
        `${this._baseUrl}/public/rest/carts/${cartId}/items`,
        {
          body: JSON.stringify({
            productId: product.productId,
            quantity,
            variantId: product.variantId,
          }),
          headers: {
            'x-vol-tenant': this._config.tenant,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      );

      return this.getCart(cartId);
    } catch (err) {
      return this.logAndThrow(err as Error, {
        cartId,
        productId,
        quantity,
        variantId,
      });
    }
  }

  public async copyCartWithoutPersonalData(
    cartId: string
  ): Promise<LambdaV2Responses['newCart']> {
    const currentCart = await this.getCart(cartId);
    const currentItems: Pick<
      LambdaV2Responses['newCartItems'],
      'productId' & 'variantId' & 'quantity'
    > = currentCart.items.map((item) => ({
      productId: item.productId,
      variantId: item.variantId,
      quantity: item.quantity,
    }));
    return this.createCart(currentItems);
  }

  public createCart(
    items?: Pick<
      LambdaV2Responses['newCartItems'],
      'productId' & 'variantId' & 'quantity'
    >
  ): Promise<LambdaV2Responses['newCart']> {
    return this._transport(`${this._baseUrl}/public/rest/carts`, {
      body: JSON.stringify({
        items: items || [],
      }),
      headers: {
        'x-vol-tenant': this._config.tenant,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then((res) => res.json());
  }

  public getCart(cartId: string): Promise<LambdaV2Responses['newCart']> {
    return this._transport(`${this._baseUrl}/public/rest/carts/${cartId}`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
      method: 'GET',
    }).then((res) => res.json());
  }

  public async getLatestCartForShopper(
    cartId: string,
    shopperId: string
  ): Promise<LambdaV2Responses['newCart']> {
    return this._transport(`${this._baseUrl}/public/rest/carts`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
        'x-vol-shopper': shopperId,
      },
      method: 'GET',
    })
      .then((res) => res.json())
      .catch(() => this.getCart(cartId));
  }

  public async removeDiscountFromCart(
    cartId: string,
    discountId: string
  ): Promise<LambdaV2Responses['newCart'] | never> {
    try {
      const cart = await this.getCart(cartId);
      const discount = cart.pricing?.appliedDiscounts.find(
        (discount) => discount.discountId === discountId
      );
      const discountCode = discount?.discountCode;

      if (discountCode === undefined)
        throw new Error('Discount code not found');

      await this._transport(
        `${this._baseUrl}/public/rest/carts/${cartId}/discounts/${discountCode}`,
        {
          headers: {
            'x-vol-tenant': this._config.tenant,
          },
          method: 'DELETE',
        }
      );

      return this.getCart(cartId);
    } catch (err) {
      return this.logAndThrow(err as Error, {
        cartId,
        discountId: discountId.toUpperCase(),
      });
    }
  }

  public async setShopperId(
    cartId: string,
    shopperId: string
  ): Promise<LambdaV2Responses['newCart']> {
    try {
      await this._transport(`${this._baseUrl}/public/rest/carts/${cartId}`, {
        body: JSON.stringify({ shopperId }),
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
        method: 'PATCH',
      });

      return this.getCart(cartId);
    } catch (err) {
      return this.logAndThrow(err as Error, {
        cartId,
        shopperId,
      });
    }
  }

  public async updateCart(
    cartId: string,
    quantity: number,
    variantId: string
  ): Promise<LambdaV2Responses['newCart']> {
    try {
      const cart = await this.getCart(cartId);
      const cartItemId = cart.items.reduce(
        (acc, item) => (item.variantId === variantId ? item.cartItemId : acc),
        ''
      );

      if (quantity <= 0) {
        return this.removeItemFromNewCart(cartId, cartItemId);
      }

      await this._transport(
        `${this._baseUrl}/public/rest/carts/${cartId}/items/${cartItemId}`,
        {
          body: JSON.stringify({ quantity: Number(quantity) }),
          headers: {
            'x-vol-tenant': this._config.tenant,
            'Content-Type': 'application/json',
          },
          method: 'PATCH',
        }
      );

      return this.getCart(cartId);
    } catch (err) {
      return this.logAndThrow(err as Error, {
        cartId,
        quantity,
        variantId,
      });
    }
  }

  private async removeItemFromNewCart(
    cartId: string,
    cartItemId: string
  ): Promise<LambdaV2Responses['newCart'] | never> {
    try {
      return this._transport(
        `${this._baseUrl}/public/rest/carts/${cartId}/items/${cartItemId}`,
        {
          headers: {
            'x-vol-tenant': this._config.tenant,
          },
          method: 'DELETE',
        }
      ).then(() => this.getCart(cartId));
    } catch (err) {
      return this.logAndThrow(err as Error, {
        cartId,
        cartItemId,
      });
    }
  }

  private async getProductIdAndVariantIdByLegacyProductIdAndLegacyVariantId(
    legacyProductId: string,
    legacyVariantId: string
  ): Promise<{ productId: string; variantId: string }> {
    const response = await this._transport(
      `${this._baseUrl}/public/rest/products?filter=legacyProductsId%20eq%20${legacyProductId}&include=variants`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
      }
    );

    const { products }: { products: NewProductResponse[] } =
      await response.json();

    if (!products.length) {
      return this.logAndThrow(
        new Error(`product not found for legacy id ${legacyProductId}`),
        {
          legacyProductId,
          legacyVariantId,
        }
      );
    }

    const product = products[0];
    if (legacyVariantId) {
      const selectedVariant = (product.variants || []).filter(
        (variant) => variant.legacyProductVariantsId === legacyVariantId
      );

      if (!selectedVariant.length) {
        return this.logAndThrow(
          new Error(
            `Selected variant not found for legacy variant id ${legacyVariantId}`
          ),
          {
            legacyProductId,
            legacyVariantId,
          }
        );
      }

      return {
        productId: product.productId,
        variantId: selectedVariant?.[0].variantId,
      };
    }

    return {
      productId: product.productId,
      variantId: (product.variants || [])[0].variantId,
    };
  }
}

export class OldCartApi extends CartApi {
  public async addDiscountToCart(
    cartId: string,
    discountCode: string
  ): Promise<LambdaV2Responses['cart']> {
    const cart = await this.getCart(cartId);
    cart.discountCode = discountCode;

    return this.saveCart(cartId, cart);
  }

  public async addToCart(
    cartId: string,
    productId: string,
    quantity: number,
    variantId: string
  ): Promise<LambdaV2Responses['cart']> {
    const cart = await this.getCart(cartId);
    const product = await this.getProductById(productId);
    const newCartItem = this.buildNewCartItem(product, quantity, variantId);
    const existingItem = this.getExistingItem(cart.items, variantId);
    if (existingItem) {
      existingItem.quantity += quantity;
    } else {
      cart.items.push(newCartItem);
    }
    return this.saveCart(cartId, cart);
  }

  public async copyCartWithoutPersonalData(
    cartId: string
  ): Promise<LambdaV2Responses['cart']> {
    const currentCart = await this.getCart(cartId);
    const newCart = await this.createCart();
    newCart.items = currentCart.items;
    return this.saveCart(newCart.id, newCart);
  }

  public createCart(): Promise<LambdaV2Responses['cart']> {
    return this._transport(`${this._baseUrl}/carts/`, {
      headers: {
        'x-mat-tenant': this._config.tenant,
      },
      method: 'POST',
    }).then((res) => res.json());
  }

  public getCart(cartId: string): Promise<LambdaV2Responses['cart']> {
    return this._transport(`${this._baseUrl}/carts/${cartId}`, {
      headers: {
        'x-mat-tenant': this._config.tenant,
      },
      method: 'GET',
    }).then((res) => res.json());
  }

  public async getLatestCartForShopper(
    cartId: string,
    shopperId: string,
    shopperToken: string
  ): Promise<LambdaV2Responses['cart']> {
    const shopperCartsResult = await this.getLatestCartsForShopper(
      shopperId,
      shopperToken
    );
    if (!shopperCartsResult || shopperCartsResult.items.length === 0) {
      return this.getCart(cartId);
    }
    return this.getCart(shopperCartsResult.items[0].id);
  }

  public async removeDiscountFromCart(
    cartId: string,
    discountId: string
  ): Promise<LambdaV2Responses['cart']> {
    const cart = await this.getCart(cartId);
    cart.discounts = this.removeDiscountItem(cart.discounts, discountId);
    return this.saveCart(cartId, cart);
  }

  public async setShopperId(
    cartId: string,
    shopperId: string
  ): Promise<LambdaV2Responses['cart']> {
    const cartResponse = await this.getCart(cartId);
    cartResponse.shopperId = shopperId;
    return this.saveCart(cartId, cartResponse);
  }

  public async updateCart(
    cartId: string,
    quantity: number,
    variantId: string
  ): Promise<CartApiResponse> {
    const cartResponse = await this.getCart(cartId);
    const existingItem = this.getExistingItem(cartResponse.items, variantId);
    if (existingItem) {
      cartResponse.items = this.updateCartItems(
        cartResponse.items,
        existingItem,
        quantity,
        variantId
      );
    }
    return this.saveCart(cartId, cartResponse);
  }

  private buildNewCartItem(
    product: LambdaV2Responses['product'],
    quantity: number,
    variantId: string
  ): LambdaV2Responses['cartItems'] {
    const variantSku = this.getVariantSku(product, variantId);
    const newCartItem = {
      product,
      quantity,
    };
    newCartItem.product.sku = variantSku;
    newCartItem.product.productVariantId = variantId;
    return newCartItem;
  }

  private getLatestCartsForShopper(
    shopperId: string,
    shopperToken: string
  ): Promise<LambdaV2Responses['shopperCarts']> {
    return this._transport(`${this._baseUrl}/shopper/carts?pageSize=1`, {
      headers: {
        Authorization: `Bearer ${shopperToken}`,
        'x-vol-shopper': shopperId,
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err as Error, {
          shopperId,
          shopperToken,
        });
      });
  }

  private getVariantSku(
    product: LambdaV2Responses['product'],
    variantId: string
  ): string {
    const variant = product.productVariants.find(
      (item) => item.id === variantId
    );
    if (!variant) {
      return product.sku;
    } else {
      return variant.sku;
    }
  }

  private getProductById(id: string): Promise<LambdaV2Responses['product']> {
    return this._transport(`${this._baseUrl}/store/products/${id}`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err as Error, {
          productId: id,
        });
      });
  }

  private getExistingItem(
    cartItems: LambdaV2Responses['cart']['items'],
    variantId: string
  ): LambdaV2Responses['cartItems'] | undefined {
    return cartItems.find(
      (items) => items.product.productVariantId === variantId
    );
  }

  private updateCartItems(
    cartItems: LambdaV2Responses['cart']['items'],
    existingItem: LambdaV2Responses['cartItems'],
    quantity: number,
    variantId: string
  ): LambdaV2Responses['cart']['items'] {
    if (quantity <= 0) {
      return this.removeCartItem(cartItems, variantId);
    } else {
      existingItem.quantity = quantity;
      return cartItems;
    }
  }

  private removeCartItem(
    cartItems: LambdaV2Responses['cart']['items'],
    variantId: string
  ): LambdaV2Responses['cart']['items'] {
    const newCartItems = cartItems.filter(
      (item) => item.product.productVariantId !== variantId
    );
    return newCartItems;
  }

  private removeDiscountItem(
    discounts: LambdaV2Responses['cart']['discounts'],
    discountId: string
  ): LambdaV2Responses['cart']['discounts'] {
    if (!discounts) {
      return [];
    }
    const newDiscountItems = discounts.filter(
      (item) => item.discount.id !== discountId
    );
    return newDiscountItems;
  }

  private saveCart(
    cartId: string,
    cart: LambdaV2Responses['cart']
  ): Promise<LambdaV2Responses['cart']> {
    return this._transport(`${this._baseUrl}/carts/${cartId}`, {
      body: JSON.stringify(cart),
      headers: {
        'x-mat-tenant': this._config.tenant,
      },
      method: 'PUT',
    })
      .then((res) => this.handleErrors(res))
      .then((res: Response) => res.json())
      .catch((err) => {
        return this.logAndThrow(err as Error, {
          cartId,
        });
      });
  }

  private handleErrors(res: Response): Response {
    if (!res.ok) {
      throw Error(res.statusText);
    }
    return res;
  }
}
