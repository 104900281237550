import { ContentPageResponse, DTO, VolusionApi } from './types';

export class ContentPages {
  constructor(private _api: VolusionApi, private _dto: DTO) {}

  public getBySeoFriendlyName(
    pageUrlText: string
  ): Promise<ContentPageResponse> {
    return this._api
      .getContentPageBySeoFriendlyName(pageUrlText)
      .then(this._dto.buildContentPageResponse);
  }
}
