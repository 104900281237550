import {
  ColorSettings,
  ConfiguredColor,
  ExternalDependencies,
  GlobalStyles,
  Palette,
} from './types';

export const getColor = (color: ConfiguredColor, palette: Palette): string => {
  if (color.selected === 'default') {
    return color.default;
  }
  if (color.custom && color.selected === 'custom') {
    return color.custom;
  }
  return palette.colors[color.selected] || color.default;
};

export const defaultPalette: Palette = {
  colors: {
    color1: '#FFFFFF',
    color2: '#333333',
    color3: '#333333',
    color4: '#333333',
    color5: '#333333',
    color6: '#333333',
  },
  name: 'Default',
};

export const getActivePalette = (colorSettings: ColorSettings): Palette => {
  let activePalette: Palette = defaultPalette;

  if ('palettes' in colorSettings) {
    const byName = ({ name }: Palette) => name === colorSettings.activePalette;
    activePalette = colorSettings.palettes.find(byName) || defaultPalette;
  }

  return activePalette;
};

export function transformGlobalComponentStyles(
  globalStylesConfig: GlobalStyles
): GlobalStyles {
  type ColorProp = string | ConfiguredColor;

  const activePalette = getActivePalette(globalStylesConfig.color);

  const buttonStyles = {
    ...globalStylesConfig.globalComponents.volComponentButton,
  };

  const transformColor = (color: ColorProp) =>
    typeof color === 'string' ? color : getColor(color, activePalette);

  type ButtonType = 'primaryButtonStyles' | 'secondaryButtonStyles';

  const isObject = (o: unknown): o is { [key: string]: unknown } =>
    o !== null && typeof o === 'object' && Array.isArray(o) === false;

  const getStyleEntries = (buttonType: ButtonType) => {
    const styles = buttonStyles[buttonType];
    if (isObject(styles)) {
      return Object.entries(styles);
    } else {
      return [];
    }
  };

  const transformButtonConfig = (buttonType: ButtonType) => {
    return getStyleEntries(buttonType).reduce(
      (transformedProps, [key, value]) => {
        const colorPropNames =
          /backgroundColor|textColor|borderColor|hoverBackgroundColor|hoverBorderColor|hoverTextColor/;
        if (key.match(colorPropNames)) {
          return {
            ...transformedProps,
            [key]: transformColor(value as ColorProp),
          };
        }
        return { ...transformedProps, [key]: value };
      },
      {}
    );
  };

  const newPrimaryStyles = transformButtonConfig('primaryButtonStyles');
  const newSecondaryStyles = transformButtonConfig('secondaryButtonStyles');

  return {
    ...globalStylesConfig,
    globalComponents: {
      ...globalStylesConfig.globalComponents,
      volComponentButton: {
        primaryButtonStyles: newPrimaryStyles,
        secondaryButtonStyles: newSecondaryStyles,
      },
    },
  };
}

type LegacyElementComponentModule = {
  factory: (
    a: {
      React: ExternalDependencies.React;
      ElementPropTypes: unknown;
    },
    aphrodite: { StyleSheet: unknown; css: unknown },
    globalStyles: GlobalStyles
  ) => {
    component: React.Component;
  };
};

type LegacyComponentHelperDependencies = {
  globalStylesConfig: GlobalStyles;
  Components: { Button: LegacyElementComponentModule };
};

type LegacyComponentDependencies = {
  aphrodite: { StyleSheet: unknown; css: unknown };
  ElementPropTypes: unknown;
  React: ExternalDependencies.React;
};

type LegacyComponentHelper = (
  deps: LegacyComponentDependencies
) => React.Component;

export function DEPRECATED_createLegacyButtonHelper({
  globalStylesConfig,
  Components,
}: LegacyComponentHelperDependencies): LegacyComponentHelper {
  return ({ React, aphrodite, ElementPropTypes }) => {
    // NOTE: Temporarily disabling this warning, because the non-deprecated replacement
    // isn't yet production-ready
    // tslint:disable-next-line: no-console
    // console.warn(
    //   'The Legacy Button Helper is only for providing temporary backwards compatibility when updating older blocks. It will soon be removed.'
    // );
    const Button = Components.Button.factory(
      { React, ElementPropTypes },
      { StyleSheet: aphrodite.StyleSheet, css: aphrodite.css },
      transformGlobalComponentStyles(globalStylesConfig)
    ).component;
    return Button;
  };
}
