import {
  ApplePayOrderResponse,
  BagResponse,
  CartApiResponse,
  CartDiscountItemResponse,
  CartDiscountResponse,
  CartItemResponse,
  CartPricing,
  CartProductImageResponse,
  CartProductResponse,
  CartResponse,
  CartTotalItemsResponse,
  CategoriesResponse,
  CategoryResponse,
  ContentPageResponse,
  CountriesResponse,
  DTO,
  ExpressCheckoutResponse,
  LambdaV2Responses,
  MenuItemResponse,
  MenusResponse,
  PagedProductResponse,
  ParentCategoryResponse,
  PaymentKeysResponse,
  ProductImageResponse,
  ProductResponse,
  StoreInfoResponse,
  SubCategoryResponse,
} from './types';

import { buildProductVariantOptionsResponse } from './ProductUtils';

export class LambdaV2DTO implements DTO {
  public buildStoreInfoResponse(
    data: LambdaV2Responses['storeInformation']
  ): StoreInfoResponse {
    const response: StoreInfoResponse = {
      acceptsStripeAsPayment: data.acceptsStripeAsPayment,
      launched: data.launched,
      name: data.name,
      paymentAutoCapture: data.paymentAutoCapture,
      seo: data.seo,
      socialNetworks: data.socialNetworks,
      storeUrl: data.storeUrl,
      tenant: data.tenant,
      thirdPartyIntegrations: {},
      useNewCartService: data.useNewCartService,
    };
    const isAuthorizeNetEnabled = data.authorizeNet
      ? data.authorizeNet.isEnabled
      : false;

    const isStripePreferred = data.authorizeNet
      ? data.authorizeNet.isStripePreferred
      : true;

    if (data.applePay && (!isAuthorizeNetEnabled || isStripePreferred)) {
      response.applePay = {
        isEnabled: data.applePay.isEnabled,
      };
    }

    if (data.authorizeNet) {
      response.authorizeNet = {
        isEnabled: data.authorizeNet.isEnabled,
        isStripePreferred: data.authorizeNet.isStripePreferred,
      };
    }

    if (data.paypal) {
      response.paypal = {
        isEnabled: data.paypal.isEnabled,
        merchantPayerId: data.paypal.merchantPayerId,
        primaryEmailConfirmed: data.paypal.primaryEmailConfirmed,
        payInFourEnabled: data.paypal.payInFourEnabled,
      };
    }

    if (data.favicon && data.favicon.imageLink) {
      response.favicon = data.favicon.imageLink.fullUri;
    }

    if (data.logo && data.logo.imageLink) {
      const { altText, description, imageLink, title } = data.logo;
      const { height, imagePath, uriBase, width } = imageLink;
      response.logo = {
        altText,
        description,
        height: height || undefined, // guarding against null
        imagePath,
        title,
        uriBase,
        width: width || undefined,
      };
    }

    if (data.thirdPartyIntegrations) {
      response.thirdPartyIntegrations = {
        addThis: data.thirdPartyIntegrations.addThis,
        addThisEnabled: data.thirdPartyIntegrations.addThisEnabled,
        facebookTrackingPixelId:
          data.thirdPartyIntegrations.facebookTrackingPixelId,
        fullstoryId: data.thirdPartyIntegrations.fullstoryId,
        googleAnalyticsId: data.googleAnalyticsId || undefined,
        googleTagManagerId:
          data.thirdPartyIntegrations.googleTagManagerId || undefined,
        liveChatId:
          (data.thirdPartyIntegrations.chat &&
            data.thirdPartyIntegrations.chat.liveChatId) ||
          undefined,
        mailchimpUrl: data.thirdPartyIntegrations.mailchimpUrl,
        privyId: data.thirdPartyIntegrations.privyId,
        yotpoAppKey:
          (data.thirdPartyIntegrations.yotpo &&
            data.thirdPartyIntegrations.yotpo.appKey) ||
          undefined,
      };
    }

    return response;
  }

  public buildCategoryListResponse(
    data: Array<LambdaV2Responses['category']>
  ): CategoriesResponse {
    const categories = data.map((category) =>
      this.buildCategoryResponse(category)
    );
    return this.buildFlatCategoriesResponse(categories);
  }

  public buildProductsResponse(
    data: LambdaV2Responses['products'],
    totalItems: number,
    totalPages: number,
    currentPage: number
  ): PagedProductResponse {
    return {
      currentPage,
      items: data.items.map(this.buildProductResponse.bind(this)),
      totalItems,
      totalPages,
    };
  }

  public buildProductResponse(
    product: LambdaV2Responses['product']
  ): ProductResponse {
    const listPrice = product.pricing && product.pricing.listPrice;
    const salePrice = product.pricing && product.pricing.salePrice;
    const variantPrice = salePrice || listPrice || product.price;
    const productVariants = this.buildProductVariantsResponse(
      product.productVariants,
      product.images,
      variantPrice
    );

    return {
      availability: product.availability,
      categoryIds: product.categoryIds,
      customFields: product.customFields,
      description: product.descriptions.long,
      id: product.id,
      images: this.buildImagesResponse(product.images),
      listPrice,
      name: product.name,
      price: product.price,
      productVariantId: product.productVariantId,
      productVariants,
      relatedProductIds: product.relatedProductIds,
      salePrice,
      seo_friendlyName: product.seo ? product.seo.friendlyName : '',
      seo_metaDescription: product.seo ? product.seo.metaDescription : '',
      seo_title: product.seo ? product.seo.title : '',
      sku: product.sku,
      variantOptions: buildProductVariantOptionsResponse(
        product.variantOptions,
        productVariants
      ),
    };
  }

  public buildRelatedProductsResponse(
    data: Array<LambdaV2Responses['product']>
  ): ProductResponse[] {
    return data.map((product) => this.buildProductResponse(product));
  }

  public buildCartResponse(cart: CartApiResponse): CartResponse {
    if ((cart as LambdaV2Responses['cart']).id) {
      return this.buildCartResponseFromOldCart(
        cart as LambdaV2Responses['cart']
      );
    }

    return this.buildCartResponseFromNewCart(
      cart as LambdaV2Responses['newCart']
    );
  }

  public buildCartTotalItemsResponse(
    cart: CartApiResponse
  ): CartTotalItemsResponse {
    if ((cart as LambdaV2Responses['cart']).id) {
      return {
        totalItems: (cart as LambdaV2Responses['cart']).totalItems,
      };
    }

    return {
      totalItems: this.getTotalItemsFromNewCart(
        cart as LambdaV2Responses['newCart']
      ),
    };
  }

  public buildBagResponse(data: LambdaV2Responses['bag']): BagResponse {
    return {
      cartId: data.cartId,
      id: data.id,
      returnUri: data.returnUri,
      tenantId: data.tenantId,
    };
  }
  public buildExpressCheckoutResponse(
    data: LambdaV2Responses['expressCheckout']
  ): ExpressCheckoutResponse {
    return {
      token: data.token,
    };
  }

  public buildRestExpressCheckoutResponse(
    data: LambdaV2Responses['restExpressCheckout']
  ): ExpressCheckoutResponse {
    return {
      token: data.id,
    };
  }

  public buildApplePayOrderResponse(
    data: LambdaV2Responses['applePayOrder']
  ): ApplePayOrderResponse {
    return data;
  }

  public buildCountriesResponse(
    data: LambdaV2Responses['countries']
  ): CountriesResponse {
    return data;
  }

  public buildPaymentKeysResponse(
    data: LambdaV2Responses['paymentKeys']
  ): PaymentKeysResponse {
    return data;
  }

  public buildContentPageResponse(
    data: LambdaV2Responses['contentPage']
  ): ContentPageResponse {
    const { content, createdOn, id, name, seo, updatedOn } = data;
    return {
      content,
      createdOn,
      id,
      name,
      seo,
      updatedOn,
    };
  }

  public buildMenusResponse = (
    data: LambdaV2Responses['menus']
  ): MenusResponse => {
    const items = data.items.map((menu) => {
      return {
        createdOn: menu.createdOn,
        id: menu.id,
        items: menu.items.map(this.buildMenuItemsResponse),
        updatedOn: menu.updatedOn,
      };
    });
    return {
      items,
    };
  };

  public buildCategoryResponse(
    data: LambdaV2Responses['category']
  ): CategoryResponse {
    return {
      categoryId: data.categoryId || '',
      contentPageId: data.contentPageId || '',
      descriptions: data.descriptions,
      id: data.id,
      images: data.images,
      name: data.name,
      parentCategories: this.buildParentCategoryResponse(data.parentCategories),
      productCount: data.productCount,
      seo: data.seo,
      state: data.state,
      subCategories: this.buildSubCategoryResponse(data.subCategories),
    };
  }

  private buildMenuItemsResponse = (
    item: LambdaV2Responses['menuItem']
  ): MenuItemResponse => {
    const itemResponse = {
      id: item.id,
      items: item.items.map(this.buildMenuItemsResponse),
      name: item.name,
      slug: item.code,
      type: item.type,
    } as MenuItemResponse;

    if (item.type === 'link') {
      itemResponse.url = item.url;
      delete itemResponse.slug;
    }
    return itemResponse;
  };

  private buildSubCategoryResponse(
    subCategories: Array<LambdaV2Responses['category']> = []
  ): SubCategoryResponse[] {
    return subCategories.map((subCategory: LambdaV2Responses['category']) => ({
      categoryId: subCategory.categoryId || '',
      contentPageId: subCategory.contentPageId || '',
      descriptions: subCategory.descriptions,
      id: subCategory.id,
      images: subCategory.images,
      name: subCategory.name,
      parentId: subCategory.parentId || '',
      productCount: subCategory.productCount,
      seo: subCategory.seo,
      state: subCategory.state,
      subCategories: this.buildSubCategoryResponse(subCategory.subCategories),
    }));
  }

  private buildParentCategoryResponse(
    parentCategories: Array<LambdaV2Responses['category']> = []
  ): ParentCategoryResponse[] {
    return parentCategories.map(
      (parentCategory: LambdaV2Responses['category']) => ({
        categoryId: parentCategory.categoryId || '',
        contentPageId: parentCategory.contentPageId || '',
        descriptions: parentCategory.descriptions,
        id: parentCategory.id,
        images: parentCategory.images,
        name: parentCategory.name,
        parentId: parentCategory.parentId || '',
        productCount: parentCategory.productCount,
        seo: parentCategory.seo,
        state: parentCategory.state,
      })
    );
  }

  private buildFlatCategoriesResponse(
    categories: CategoriesResponse
  ): CategoriesResponse {
    return categories.reduce(
      (
        arr: CategoriesResponse,
        category: CategoryResponse | SubCategoryResponse
      ) => {
        if (category.subCategories.length === 0) {
          return [...arr, category];
        }
        return [
          ...arr,
          category,
          ...this.buildFlatCategoriesResponse(category.subCategories),
        ];
      },
      []
    );
  }

  private buildProductVariantsResponse(
    productVariants: LambdaV2Responses['product']['productVariants'],
    images: LambdaV2Responses['product']['images'],
    variantPrice: number
  ): ProductResponse['productVariants'] {
    const visibleProductVariants = productVariants.filter(
      (variant) => variant.isShopperVisible !== false
    );
    return visibleProductVariants.map((variant) => {
      const imageLinkIds = variant.imageLinkIds || [];
      const skuImageLinkIds = variant.skuImageLinkIds || [];
      const imageLinkIdsAll = [...imageLinkIds, ...skuImageLinkIds];
      return {
        id: variant.id,
        images: this.buildProductVariantImagesResponse(images, imageLinkIdsAll),
        isInventoryTracked: variant.isInventoryTracked || false,
        price: variant.price || variantPrice,
        quantity: variant.quantity || 0,
        sku: variant.sku,
        variants: variant.variants,
      };
    });
  }

  private buildProductVariantImagesResponse(
    images: LambdaV2Responses['product']['images'],
    imageLinkIdsAll: string[]
  ): ProductImageResponse[] {
    if (imageLinkIdsAll.length > 0) {
      return this.buildImagesResponse(
        images.filter((image) => {
          return imageLinkIdsAll.includes(image.imageLink.id);
        })
      );
    } else {
      return this.buildImagesResponse(images);
    }
  }

  private buildCartItemsResponse(
    data: LambdaV2Responses['cart']['items']
  ): CartItemResponse[] {
    return data.map(this.buildCartItemResponse.bind(this));
  }

  private buildCartItemResponse(
    cartItem: LambdaV2Responses['cartItems']
  ): CartItemResponse {
    return {
      product: this.buildCartProductResponse(cartItem.product),
      quantity: cartItem.quantity,
    };
  }

  private buildCartProductResponse(
    product: LambdaV2Responses['product']
  ): CartProductResponse {
    return {
      description: product.descriptions.long,
      id: product.id,
      images: this.buildCartImagesResponse(product.images),
      listPrice: product.pricing && product.pricing.listPrice,
      name: product.name,
      price: product.price,
      productVariantId: product.productVariantId,
      salePrice: product.pricing && product.pricing.salePrice,
      variants: product.variants,
    };
  }

  private buildCartItemsResponseFromNewCart(
    data: LambdaV2Responses['newCart']['items']
  ): CartItemResponse[] {
    return data.map(this.buildCartItemResponseFromNewCart.bind(this));
  }

  private buildCartItemResponseFromNewCart(
    cartItem: LambdaV2Responses['newCartItems']
  ): CartItemResponse {
    return {
      product: this.buildCartProductResponseFromNewCart(cartItem),
      quantity: cartItem.quantity,
    };
  }

  private buildCartProductResponseFromNewCart(
    item: LambdaV2Responses['newCartItems']
  ): CartProductResponse {
    return {
      cartItemId: item.cartItemId,
      description: '',
      id: item.productId,
      name: item.productName,
      price: item.price.amount / 100,
      salePrice: item.price.amount / 100,
      productVariantId: item.variantId,
      images: this.buildCartImagesResponseFromImageSrc(item.imageSrc || ''),
    };
  }

  private buildDiscountsResponse(
    data: LambdaV2Responses['cart']['discounts']
  ): CartDiscountItemResponse[] {
    if (!data) {
      return [];
    }
    return data.map(this.buildDiscountItemsResponse.bind(this));
  }

  private buildDiscountItemsResponse(
    discountItem: LambdaV2Responses['discountItems']
  ): CartDiscountItemResponse {
    return {
      discount: this.buildDiscountResponse(discountItem.discount),
      discountAmount: discountItem.discountAmount,
    };
  }

  private buildDiscountResponse(
    discount: LambdaV2Responses['discount']
  ): CartDiscountResponse {
    return {
      id: discount.id,
      name: discount.name,
      requiresCouponCode: discount.criteria.requiresCouponCode,
    };
  }

  private buildDiscountsResponseFromNewCart(
    data: CartPricing['appliedDiscounts'] | undefined
  ): CartDiscountItemResponse[] {
    if (!data) return [];

    return data.map(this.buildDiscountItemsResponseFromNewCart.bind(this));
  }

  private buildDiscountItemsResponseFromNewCart(
    data: LambdaV2Responses['newCartDiscountItems']
  ): CartDiscountItemResponse {
    return {
      discount: {
        discountCode: data.discountCode,
        id: data.discountId,
        name: data.name,
        requiresCouponCode: !!data.discountCode,
      },
      discountAmount: data.amount / 100,
    };
  }

  private splitCartImageSrc(imageSrc = ''): {
    uriBase?: string;
    imagePath?: string;
  } {
    const splitKey = 'upload/';
    if (!imageSrc.includes(splitKey)) return {};

    const indexOfSplit = imageSrc.indexOf(splitKey) + splitKey.length;
    return {
      uriBase: imageSrc.substring(0, indexOfSplit),
      imagePath: imageSrc.substring(indexOfSplit),
    };
  }

  private buildCartImagesResponseFromImageSrc(
    imageSrc: string
  ): CartProductImageResponse[] {
    const image = this.splitCartImageSrc(imageSrc);
    return [
      {
        fullUri: imageSrc || '',
        altText: '',
        description: '',
        title: '',
        imagePath: image.imagePath || '',
        uriBase: image.uriBase || '',
      },
    ];
  }

  private buildCartImagesResponse(
    images: LambdaV2Responses['product']['images']
  ): CartProductImageResponse[] {
    return images.map((image) => {
      const { altText, description, imageLink, title } = image;
      const { fullUri, height, imagePath, uriBase, width } = imageLink;
      return {
        altText,
        description,
        fullUri,
        height: height || undefined, // guarding against null
        imagePath,
        title,
        uriBase,
        width: width || undefined,
      };
    });
  }

  private buildImagesResponse(
    images: LambdaV2Responses['product']['images']
  ): ProductResponse['images'] {
    return images.map((image) => {
      const { altText, description, imageLink, title } = image;
      const { fullUri, height, id, imagePath, uriBase, width } = imageLink;
      return {
        altText,
        description,
        fullUri,
        height: height || undefined, // guarding against null
        id,
        imagePath,
        title,
        uriBase,
        width: width || undefined,
      };
    });
  }

  private getTotalItemsFromNewCart(cart: LambdaV2Responses['newCart']): number {
    return cart.items.reduce((acc, item) => (acc += item.quantity), 0);
  }

  private buildCartResponseFromOldCart(
    cart: LambdaV2Responses['cart']
  ): CartResponse {
    return {
      availableShippingMethods: cart.availableShippingMethods,
      billingAddress: cart.billingAddress,
      contactInfo: cart.contactInfo,
      discounts: this.buildDiscountsResponse(cart.discounts),
      grandTotal: cart.grandTotal,
      id: cart.id,
      items: this.buildCartItemsResponse(cart.items),
      messages: cart.messages,
      revision: cart.revision,
      shippingAddress: cart.shippingAddress,
      shippingMethod: cart.shippingMethod,
      taxAmount: cart.taxAmount,
      taxRate: cart.taxRate,
      total: cart.total,
      totalItems: cart.totalItems,
    };
  }

  private buildCartResponseFromNewCart(
    cart: LambdaV2Responses['newCart']
  ): CartResponse {
    return {
      availableShippingMethods: [],
      id: cart.cartId,
      total: (cart.pricing?.total.amount || 0) / 100,
      totalItems: cart.items.reduce((acc, item) => acc + item.quantity, 0),
      items: this.buildCartItemsResponseFromNewCart(cart.items),
      messages: [],
      taxAmount: undefined,
      taxRate: undefined,
      discounts: this.buildDiscountsResponseFromNewCart(
        cart.pricing?.appliedDiscounts
      ),
    };
  }
}
