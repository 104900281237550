interface BasicMeta {
  content: string;
  changeable: boolean;
}

interface ExtraMeta extends BasicMeta {
  property: string;
  name: string | null;
}

interface MetaInfo {
  title: BasicMeta;
  description: BasicMeta;
  extraTags: ExtraMeta[];
}

export class Seo {
  private _metaInfo: Partial<MetaInfo> = {
    extraTags: [],
  };

  public setTitle(content: string, changeable = false): void {
    this.setBasicTag('title', content, changeable);
  }

  public setDescription(content: string, changeable = false): void {
    this.setBasicTag('description', content, changeable);
  }

  public addExtraTag(
    property: string,
    content: string,
    changeable = false,
    name: string | null = null
  ): void {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    const tags = this._metaInfo.extraTags!;
    const foundTag = tags.find((tag) => tag.property === property);
    if (!foundTag) {
      tags.push({
        changeable,
        content,
        name,
        property,
      });
    }
  }

  public clearMeta(): void {
    this._metaInfo = {
      description: undefined,
      extraTags: [],
      title: undefined,
    };
  }

  public getMeta(): Partial<MetaInfo> {
    return this._metaInfo;
  }

  private setBasicTag(
    property: keyof Pick<MetaInfo, 'title' | 'description'>,
    content: string,
    changeable = false
  ): void {
    this._metaInfo[property] = {
      changeable,
      content,
    };
  }
}
